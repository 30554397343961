import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../AdminSystem/NewUser.css';
import Sidebar from './SideBar';
import '../AdminSystem/EditUser.css';

const EditUser = () => {
  // Extract id from URL using useParams
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize navigate

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    companyName: '',
    address: '',
    city: 'Surat',
    state: 'Gujarat',
    contactPersonName: '',
    contactPersonNumber: '',
    softwareCategory: '',
    defaultDB: '',
    dbUsername: '',
    dbPassword: '',
    staticIP: '',
    port: '',
    remark: '',
    validFrom: new Date(),
    validTo: new Date(),
  });

  const [loading, setLoading] = useState(false); // To track loading state
  const [error, setError] = useState(''); // To track errors
  const [additionalUsers, setAdditionalUsers] = useState([]); // New state for additional users

  // Fetch existing admin data if id exists (edit mode)
  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`http://dsapp.shanti-pos.com:8080/user/system-admin/${id}`)
        .then((response) => {

          setFormData({
            ...response.data,
            validFrom: new Date(response.data.validFrom),
            validTo: new Date(response.data.validTo),
          });
        })
        .catch((error) => {
          console.error('Error fetching system admin:', error);
          setError('Error fetching user details. Please try again.');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // Fetch additional users for the current system admin
    axios
      .get(`http://dsapp.shanti-pos.com:8080/additionalUser/all/${id}`)
      .then((response) => {

        setAdditionalUsers(response.data.data); // Fix: Access 'data' to set additionalUsers
      })
      .catch((error) => {
        console.error('Error fetching additional users:', error);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(
        `http://dsapp.shanti-pos.com:8080/user/system-admin/${id}`,
        formData
      );
      console.log('System Admin updated:', response.data);
      alert('System Admin updated successfully!');
      navigate('/get-user');
    } catch (error) {
      console.error('Error updating system admin:', error);
      alert('There was an error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`http://dsapp.shanti-pos.com:8080/user/system-admin/${id}`);
      alert('System Admin deleted successfully!');
      navigate('/get-user');
    } catch (error) {
      console.error('Error deleting system admin:', error);
      alert('There was an error deleting the system admin. Please try again later.');
    }
  };

  // Navigate to the Additional User page
  const handleAdditionalConnection = () => {
    navigate(`/additional-user/${id}`); // Navigate to the additional user page with systemAdmin id
  };

   // Navigate to edit additional user page
   const handleEditAdditionalUser = (additionalUserId) => {
    navigate(`/edit-additional-user/${id}/${additionalUserId}`);
  };

  return (
    <div className="sys-admin-form-container-edit">
      <Sidebar />
      <h2>Edit User</h2>
      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="sys-admin-form">
        {/* Email, Password and Company Name Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Address, City, State Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="state">State</label>
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Contact Person Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="contactPersonName">Contact Person Name</label>
            <input
              type="text"
              id="contactPersonName"
              name="contactPersonName"
              value={formData.contactPersonName}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="contactPersonNumber">Contact Person Number</label>
            <input
              type="text"
              id="contactPersonNumber"
              name="contactPersonNumber"
              value={formData.contactPersonNumber}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="softwareCategory">Software Category</label>
            <select
              id="softwareCategory"
              name="softwareCategory"
              value={formData.softwareCategory}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Garment R">Garment R</option>
              <option value="Garment W">Garment W</option>
              <option value="SuperStore">SuperStore</option>
              <option value="Restaurant">Restaurant</option>
              <option value="WeighBridge">WeighBridge</option>
              <option value="Jari">Jari</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        {/* Default Database, DB Username, and DB Password Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="defaultDB">Default Database</label>
            <input
              type="text"
              id="defaultDB"
              name="defaultDB"
              value={formData.defaultDB}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="dbUsername">Database Username</label>
            <input
              type="text"
              id="dbUsername"
              name="dbUsername"
              value={formData.dbUsername}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="dbPassword">Database Password</label>
            <input
              type="password"
              id="dbPassword"
              name="dbPassword"
              value={formData.dbPassword}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Static IP and Port Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="staticIP">Static IP</label>
            <input
              type="text"
              id="staticIP"
              name="staticIP"
              value={formData.staticIP}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="port">Port</label>
            <input
              type="number"
              id="port"
              name="port"
              value={formData.port}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Valid From and Valid To Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="validFrom">Valid From</label>
            <DatePicker
              selected={formData.validFrom}
              onChange={(date) => handleDateChange(date, 'validFrom')}
              dateFormat="dd/MM/yyyy"
              required
              className="form-date-picker"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              scrollableMonthYearDropdown
            />
          </div>
          <div className="form-field">
            <label htmlFor="validTo">Valid To</label>
            <DatePicker
              selected={formData.validTo}
              onChange={(date) => handleDateChange(date, 'validTo')}
              dateFormat="dd/MM/yyyy"
              required
              className="form-date-picker"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              scrollableMonthYearDropdown
            />
          </div>
        </div>

        {/* Remark Field */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="remark">Remark</label>
            <input
              type="text"
              id="remark"
              name="remark"
              value={formData.remark}
              onChange={handleChange}
              className="full-width"
            />
          </div>
        </div>

        <div className="button-container">
          <button type="submit" className="sys-admin-update-btn" disabled={loading}>
            Update
          </button>

          <button
            type="button"
            onClick={handleAdditionalConnection}
            className="sys-admin-update-btn"
          >
            Additional Connection
          </button>

          {id && (
            <button
              type="button"
              onClick={handleDelete}
              className="sys-admin-delete-btn"
            >
              Delete
            </button>
          )}
        </div>
      </form>

      {/* Table to show additional users */}
      <div className="container-table-additional">
      
        <table className="additional-table">
          <thead>
            <tr className="additional-header-row">
              <th className="additional-column">Additional Branch</th>
              <th className="additional-column">StaticIP</th>
              <th className="additional-column">defaultDB</th>
              <th className="additional-column">DB User</th>
              <th className="additional-column">DB Pass</th>
              <th className="additional-column">Port</th>
              <th className="additional-column">Actions</th> {/* New column for actions */}
            </tr>
          </thead>
          <tbody>
            {additionalUsers && additionalUsers.length > 0 ? (
              additionalUsers.map((user) => (
                <tr key={user._id} className="additional-row">
                  <td className="additionalUser-data">{user.additionalBranch}</td>
                  <td className="additionalUser-data">{user.staticIP}</td>
                  <td className="additionalUser-data">{user.defaultDB}</td>
                  <td className="additionalUser-data">{user.dbUsername}</td>
                  <td className="additionalUser-data">{user.dbPassword}</td>
                  <td className="additionalUser-data">{user.port}</td>
                  <td className="additionalUser-data">
                    <button
                      type="button"
                      onClick={() => handleEditAdditionalUser(user._id)} // Pass additional user id to the next page
                      className="edit-button"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="additional-no-data">
                <td colSpan="7" className="additional-no-data-message">
                  No additional users found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>


  );
};

export default EditUser;

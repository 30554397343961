import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../AdminSystem/SideBar.js';

const EditAdditionalUser = () => {
  const { id, additionalUserId } = useParams(); // Access both ids from URL
  const navigate = useNavigate();

  // Initializing form data with empty strings
  const [formData, setFormData] = useState({
    additionalBranch: '',
    staticIP: '',
    defaultDB: '',
    dbUsername: '',
    dbPassword: '',
    port: '',
  });

  const [loading, setLoading] = useState(false); // Loading state for fetching data
  const [error, setError] = useState(null); // Error state to show if fetch fails

  useEffect(() => {
    setLoading(true);
    // Fetch additional user data using both the system admin id and the additional user id
    axios
      .get(`http://dsapp.shanti-pos.com:8080/additionalUser/one/${id}/${additionalUserId}`)
      .then((response) => {
        const { data } = response.data; // Extract data from the response
        // Map the response data to formData state
        setFormData({
          additionalBranch: data.additionalBranch,
          staticIP: data.staticIP,
          defaultDB: data.defaultDB,
          dbUsername: data.dbUsername,
          dbPassword: data.dbPassword,
          port: data.port,
        });
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching additional user data');
        setLoading(false);
        console.error('Error fetching additional user:', error);
      });
  }, [id, additionalUserId]); // Re-run effect when id or additionalUserId changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Disable the button and show loading state while submitting
    try {
      await axios.put(
        `http://dsapp.shanti-pos.com:8080/additionalUser/update/${id}/${additionalUserId}`,
        formData // Pass the form data to update
      );
      alert('Additional user updated successfully!');
      navigate(`/edit-user/${id}`); // Redirect back to the edit user page after update
    } catch (error) {
      setError('Error updating additional user');
      console.error('Error updating additional user:', error);
    } finally {
      setLoading(false); // Reset loading state after request is complete
    }
  };

  const handleDelete = async () => {
    // Confirm before deleting
    const confirmDelete = window.confirm('Are you sure you want to delete this user?');
    if (!confirmDelete) return;

    setLoading(true); // Show loading state while deleting
    try {
      await axios.delete(
        `http://dsapp.shanti-pos.com:8080/additionalUser/delete/${id}/${additionalUserId}`
      );
      alert('Additional user deleted successfully!');
      navigate(`/edit-user/${id}`); // Redirect back to the edit user page after deletion
    } catch (error) {
      setError('Error deleting additional user');
      console.error('Error deleting additional user:', error);
    } finally {
      setLoading(false); // Reset loading state after request is complete
    }
  };

  return (
    <div className="sys-admin-form-container-edit">
      <Sidebar />
      <h2>Edit Additional User</h2>
      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="sys-admin-form">
        {/* Additional Branch Field */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="additionalBranch">Additional Branch</label>
            <input
              type="text"
              id="additionalBranch"
              name="additionalBranch"
              value={formData.additionalBranch}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Static IP, DB, Username, Password, and Port Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="staticIP">Static IP</label>
            <input
              type="text"
              id="staticIP"
              name="staticIP"
              value={formData.staticIP}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="defaultDB">Default Database</label>
            <input
              type="text"
              id="defaultDB"
              name="defaultDB"
              value={formData.defaultDB}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="dbUsername">Database Username</label>
            <input
              type="text"
              id="dbUsername"
              name="dbUsername"
              value={formData.dbUsername}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="dbPassword">Database Password</label>
            <input
              type="password"
              id="dbPassword"
              name="dbPassword"
              value={formData.dbPassword}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="port">Port</label>
            <input
              type="number"
              id="port"
              name="port"
              value={formData.port}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="button-container">
          <button type="submit" className="sys-admin-update-btn" disabled={loading}>
            {loading ? 'Updating...' : 'Update User'}
          </button>

          <button
            type="button"
            className="sys-admin-delete-btn"
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete User'}
          </button>
        </div>

      </form>
    </div>
  );
};

export default EditAdditionalUser;

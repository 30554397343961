import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaUsers, FaSignOutAlt, FaBars } from 'react-icons/fa'; // FontAwesome Icons
import './SideBar.css';
import Logo from '../css/images.png';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to track sidebar visibility
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768); // Detect if screen width is large
  const sidebarRef = useRef(null); // Reference to the sidebar container
  const navigate = useNavigate();

  // Memoized handleClickOutside function
  const handleClickOutside = useCallback((e) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target) && !isLargeScreen) {
      setIsSidebarOpen(false);
    }
  }, [isLargeScreen]);

  // Attach event listener when the component mounts
  useEffect(() => {
    document.addEventListener('click', handleClickOutside); // Listen for clicks

    // Listen for window resize and update screen width state
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768); // Update based on screen width
    };

    window.addEventListener('resize', handleResize); // Add resize event listener
    return () => {
      document.removeEventListener('click', handleClickOutside); // Clean up on unmount
      window.removeEventListener('resize', handleResize); // Clean up resize event listener
    };
  }, [handleClickOutside]); // Add 'handleClickOutside' as a dependency

  // When screen size changes, ensure the sidebar stays open on large screens
  useEffect(() => {
    if (isLargeScreen) {
      setIsSidebarOpen(true); // Automatically open sidebar on large screens
    } else {
      setIsSidebarOpen(false); // Close the sidebar on small screens
    }
  }, [isLargeScreen]);

  const handleLogout = () => {
    // Clear everything from localStorage and sessionStorage
    localStorage.clear();  // Clears all items from localStorage
    sessionStorage.clear();  // Clears all items from sessionStorage
    localStorage.setItem('isAuthenticated', 'false');  // Set isAuthenticated to false in localStorage
    navigate('/admin-login'); // Navigate to the login page
    window.location.reload();
  };

  const toggleSidebar = (e) => {
    e.stopPropagation(); // Prevent the click event from being passed to the document
    setIsSidebarOpen(prevState => !prevState); // Toggle the sidebar state (only on small screens)
  };

  const handleLinkClick = () => {
    // On small screens, clicking a link will close the sidebar
    if (!isLargeScreen) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <>
      {/* Toggle Button visible only on small screens when sidebar is collapsed */}
      {!isLargeScreen && (
        <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
          <FaBars className="sidebar-toggle-icon" />
        </button>
      )}

      {/* Sidebar */}
      <div 
        ref={sidebarRef} 
        className={`sidebar-container ${isSidebarOpen ? 'open' : ''}`}
      >
        <div className="sidebar-header-section">
          <img src={Logo} alt="Logo" className="sidebar-logo-img" />
        </div>
        <ul className="sidebar-nav-links">
          <li>
            <Link to="/system-admin" className="sidebar-nav-link" onClick={handleLinkClick}>
              <FaUser className="sidebar-icon" />
              New User
            </Link>
          </li>
          <li>
            <Link to="/get-user" className="sidebar-nav-link" onClick={handleLinkClick}>
              <FaUsers className="sidebar-icon" />
              View Users
            </Link>
          </li>
          <li>
            <button onClick={handleLogout} className="logout-btn-admin">
              <FaSignOutAlt className="sidebar-icon" />
              Logout
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;

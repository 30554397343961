import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ShowUser.css';
import Sidebar from './SideBar';

const SystemAdminsTable = () => {
  const [admins, setAdmins] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [adminsPerPage] = useState(10);  // Adjust number of rows per page as needed
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('http://dsapp.shanti-pos.com:8080/user/system-admins')
      .then(response => {
        setAdmins(response.data);  // Assuming response.data is an array of admin objects
      })
      .catch(error => {
        console.error('There was an error fetching the data:', error);
      });
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit-user/${id}`);
  };

  // Helper function to format date as dd-mm-yyyy and include time
  const formatDateTime = (date) => {
    const options = { 
      day: '2-digit', month: '2-digit', year: 'numeric', 
      hour: '2-digit', minute: '2-digit', second: '2-digit', 
      hour12: true
    };
    const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
    return formattedDate;
  };

  const isDatePassed = (validTo) => {
    const today = new Date();
    const validToDate = new Date(validTo);
    return validToDate < today;
  };

  // Filter admins based on company name or static IP
  const filteredAdmins = admins.filter(admin => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return (
      admin.companyName.toLowerCase().includes(lowercasedSearchTerm) ||
      admin.staticIP.toLowerCase().includes(lowercasedSearchTerm)
    );
  });

  // Pagination logic
  const indexOfLastAdmin = currentPage * adminsPerPage;
  const indexOfFirstAdmin = indexOfLastAdmin - adminsPerPage;
  const currentAdmins = filteredAdmins.slice(indexOfFirstAdmin, indexOfLastAdmin);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="table-container-admin">
      <Sidebar />
      <h2>Users</h2>

      {/* Single Search Box */}
      <div className="search-boxes">
        <label>Search:</label>
        <input 
          type="text" 
          placeholder="Company or StaticIP" 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
        />
      </div>

      <table className="admins-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Company Name</th>
            <th>Contact Person</th>
            <th>Contact Number</th>
            <th>Valid From</th>
            <th>Valid To</th>
            <th>Static IP</th>
            <th>Port</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {currentAdmins.length > 0 ? currentAdmins.map((admin) => (
            <tr key={admin._id}>
              <td>{admin.email}</td>
              <td>{admin.companyName}</td>
              <td>{admin.contactPersonName}</td>
              <td>{admin.contactPersonNumber}</td>
              <td>{formatDateTime(admin.validFrom)}</td>
              <td style={{ color: isDatePassed(admin.validTo) ? 'red' : 'black' }}>
                {formatDateTime(admin.validTo)}
              </td>
              <td>{admin.staticIP}</td>
              <td>{admin.port}</td>
              <td>
                <button className="edit-btn" onClick={() => handleEdit(admin._id)}>
                  Edit
                </button>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="9">No matching records found.</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination">
        <button 
          onClick={() => paginate(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          &lt; 
        </button>
        <span>Page {currentPage}</span>
        <button 
          onClick={() => paginate(currentPage + 1)} 
          disabled={currentPage * adminsPerPage >= filteredAdmins.length}
        >
           &gt;
        </button>
      </div>
    </div>
  );
};

export default SystemAdminsTable;
